import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { signUp } from '../../../services/auth.service';

export const BecomeCrewder = ({ active, closeModal }) => {

  const { register, handleSubmit, errors, getValues, watch } = useForm();
  const [signing, setSigning] = useState(false);
  const [globalError, setGlobalError] = useState();



  const resetForm = () => {
    setSigning(false);
    setGlobalError(null);
  };

  let emailRef = React.useRef(null);
  React.useEffect(() => {
    if (active) {
      resetForm();
      emailRef.current.focus();
    }

  }, [active]);

  const onSubmit = values => {
    setSigning(true);
    const { email, password } = values;
    signUp(email, password).then(user => {
      console.log('joo', user);
      setSigning(false);
    }, error => {
      setSigning(false);
      setGlobalError(error.message);
    });
    console.log('values', values);
  };
  return (
    <div className={active ? 'modal is-active' : 'modal'}>
      <div className="modal-background"></div>
      <div className="modal-content  is-size-5">

        <div className="has-background-modal box has-text-centered">

        <a onClick={closeModal} className="is-large is-pulled-right" aria-label="close">
                <span className="icon is-medium is-right">
                  <i className="fas fa-times fa"></i>
                </span>
              </a>
          <div className="columns is-centered">
            <div className="column is-two-thirds">
             
              <h2 className="subtitle is-size-3 is-family-raleway-black">Become a Crewder</h2>

              <form className="signupForm" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <div className="control">
                    <input ref={e => {
                      register(e, {
                        required: 'email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address'
                        }
                      });
                      emailRef.current = e;

                    }} name="email" className="input has-text-centered is-family-raleway-bold-italic is-medium has-background-signup is-rounded" type="email" placeholder="Your email *" />
                  </div>
                  {errors.email && <p className="help ml-3  is-size-5 is-error has-text-left">{errors.email.message}</p>}
                </div>

                <div className="field">
                  <div className="control">
                    <input className="input is-family-raleway-bold-italic is-medium has-text-centered is-rounded has-background-signup" type="password" ref={register({
                      required: 'Please specify password'
                    })} name="password" placeholder="Enter password *" />

                  </div>
                  {errors.password && <p className="help ml-3  is-size-5 is-error has-text-left">{errors.password.message}</p>}

                </div>
                <div className="field">
                  <div className="control">
                    <input className="input is-family-raleway-bold-italic is-medium has-text-centered is-rounded has-background-signup" ref={register({
                      required: 'Retyped password must be given',
                      validate: value => value === watch('password') ? true : 'Retyped password should match the original password'
                    })} type="password" name="passwordconfirm" placeholder="Confirm password *" />
                  </div>
                  {errors.passwordconfirm && <p className="help ml-3  is-size-5 is-error has-text-left">{errors.passwordconfirm.message}</p>}

                </div>
                <div className="field">
                  <div className="control">
                    <button disabled={Object.keys(errors).length > 0} type="submit" className={"button has-text-white is-fullwidth is-medium has-background-signup is-family-raleway-black is-rounded " + (signing ? "is-loading" : "")}>SIGNUP</button>
                  </div>
                  {globalError && <p className="help ml-3  is-size-5 is-error has-text-left">{globalError}</p>}

                </div>

              </form>
              <p className="is-family-raleway-semi-bold mt-2">
                Already a member? <a className="is-link  has-text-black">Login here!</a></p>
            </div>
          </div>

        </div>

      </div>
    </div>
  );

};