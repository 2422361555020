import { Button, Spinner } from "react-bootstrap";
import React from 'react';

export const CrewderButton = ({ children, loading, onClick, variant, type }) => {
  const className = variant === 'secondary' ? 'btn-secondary' : null;
  return <Button className={className} type={type || 'button'}  style={{ borderRadius: '15px' }} onClick={onClick} block variant="primary">
    {
      loading && <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    }

    {
      !loading && children

    }
  </Button>
};