// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrmaaLdCkDggT3iWz-oezLvr08VUQTezs",
  authDomain: "crewder.firebaseapp.com",
  databaseURL: "https://crewder.firebaseio.com",
  projectId: "crewder",
  storageBucket: "crewder.appspot.com",
  messagingSenderId: "1068896229466",
  appId: "1:1068896229466:web:d0ba5e4f844d8931baa063",
  measurementId: "G-LM7K95LX9M"
};

firebase.initializeApp(firebaseConfig);

export { firebase };