
import { firebase } from './../firebase.config';
 
export const signIn = (userName, password) => {
  return firebase.auth().signInWithEmailAndPassword(userName, password);
};

export const signUp = (userName, password) => {
  return firebase.auth().createUserWithEmailAndPassword(userName, password)
};

export const isAuthStatusChanged = (callback) => {
  firebase.auth().onAuthStateChanged(callback);
};