import React, { Fragment, useState } from "react";
import { CrewderBreadcrumb } from "../shared/crewder-breadcrumb";
import { Form, Col, Button, Row, Spinner } from "react-bootstrap";
import { CrewderButton } from '././../shared/crewder-button';
import { signIn } from './../../services/auth.service';
import { useLoginForm } from "../../custom-hooks/login-form.hook";
import { useValidate } from "../../custom-hooks";
import { Link } from "react-router-dom";
export const Login = () => {
  const [email, password, handleChange] = useLoginForm();
  const { errors, required, setErrors } = useValidate();
  const [ loading, toggleLoading ] = useState(false);
  const [ validated, setValidated ] = useState(false);
  const handleChangeAndValidateRequired = (event) => {
    handleChange(event);
    required(event);
  };
  const loginNow = (event) => {
    setErrors({});
    event.preventDefault();
    toggleLoading(true);
    signIn(email,  password)
      .then(success => console.log('jes', success))
      .catch(error => {
        if (error.code) {
          setErrors({
            email: 'Wrong username',
            password: 'Wrong password'
          });
          setValidated(true);
          toggleLoading(false);
        }
      });
  };
  return (
    <Fragment>
      <CrewderBreadcrumb path={[
        {
          label: 'Crewder',
          path: '/'
        }
      ]} active="Kirjaudu sisään" />
      <Row className="justify-content-md-center mb-5" >
        <Col xs lg={6}>
          <h4 className="text-primary text-center">Welcome to Crewder</h4>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs lg={6}>
          <Form className="text-center" noValidate validated={validated}>
            <Form.Row className="mb-3 justify-content-md-center">
              <Col xs>
                <Form.Control isInvalid={!!errors.email} id="email" type="email" value={email} onChange={handleChangeAndValidateRequired} placeholder="Anna sähköpostiosoite (xyz@gmail.com)">
                </Form.Control>
                <Form.Control.Feedback className="text-left" type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="mb-3 justify-content-md-center">
              <Col xs>
                <Form.Control id="password" isInvalid={!!errors.email} value={password} onChange={handleChange} type="password" placeholder="Syötä salasana">
                </Form.Control>
                <Form.Control.Feedback className="text-left" type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="justify-content-md-center text-left">
              <Col xs><span>Forgot password?</span>
              </Col>
            </Form.Row>
            <Form.Row className="mb-5 justify-content-md-center text-left">
              <Col xs>
                <span>Not a member yet?</span>
                <Link to="/signup">Join now</Link>

              </Col>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
              <Col xs={12} lg={8}>
                <CrewderButton loading={loading} onClick={(event) => loginNow(event)}>Sign in</CrewderButton>

                
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>

    </Fragment>
  )

};