import { Header } from "./header";
import { HeaderSearchResults } from './header-search-results';
import React from 'react';
import { useLocation } from "react-router-dom";

export const HeaderContainer = (props) => {
  const location = useLocation();
  if (location.pathname.indexOf('searchresults') > - 1) {
    return (<HeaderSearchResults></HeaderSearchResults>);
  }
  return <Header></Header>

};