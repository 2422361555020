import { useState } from 'react';



export const useSearchForm = (initialState) => {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    (id, value) => {
      const newFields = {
        ...fields,
        [id]: value
      };
      setValues(newFields);
    },
    setValues,
     (event) => {
      event.preventDefault();
      console.log('fields', fields);
    }
  ];
};

export { useLoginForm } from './login-form.hook';
export { useValidate } from './validate.hook';