import React, { Fragment, useEffect } from 'react';
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';


export const ExploreDepartments = () => {

  useEffect(() => {
    bulmaCarousel.attach('#carousel-crewder', {
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      slidesToShow: 4,
      navigation: false,
      navigationKeys: false,
      pagination: false,
      timing: 'linear',
      pauseOnHover: false,
      duration: 2500,
      loop: true,
      infinite: true
    })

  }, [])
  const departments = ['Head of Departments', 'Showrunners', 'Producers', 'Directors', 'Writers', 'Production', 'Financier', 'Production Counselors', 'Dramaturgs', 'Script Editors', 'Camera & Lights'];
  return (
    <section className="section mb-6">
      <div className="carousel" id="carousel-crewder">
        {departments.map((department, index) =>
          <div className={'mx-3 item-' + (index + 1)}>
            <button className="button is-parallelogram is-size-6 is-fullwidth is-white is-uppercase has-text-btn has-departments-color is-family-raleway-extra-bold-italic">
              <span>{department}</span>
            </button>
          </div>
        )}
      </div>
    </section>
  );

}