
import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

export const CrewderBreadcrumb = ({ path, active }) => {
  const breadcrumbItems = path.map(pathItem =>
    <Breadcrumb.Item key={pathItem.path} href={pathItem.path}>{pathItem.label}</Breadcrumb.Item>
  );

  return (
    <Breadcrumb className="bg-light font-weight-bold">
      {breadcrumbItems}
      <Breadcrumb.Item active>{active}</Breadcrumb.Item>
    </Breadcrumb>
  )

};