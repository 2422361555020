import React, { Fragment } from 'react';
import { Badge } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import styles from './selected-item.module.css';
export const SelectedItem = ({ label, onRemove }) => {
  return (
    <Fragment>
    <Badge variant="primary">{label}
    <X  onClick={() => onRemove(label)} className={styles.removeicon + ' ml-1'} />

    </Badge>
    </Fragment>
  )

}