import React, { useState } from 'react';
import './App.css';
import { HeaderContainer } from './components/shared/header-container';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { SearchPage } from './components/search-page';
import { SearchResultsPage } from './components/search-results-page';
import { MainPage } from './components/main-page';
import { userContext } from './user.context';
import { Login } from './components/login';
import { Signup } from './components/signup';
import { Footer } from './components/shared/footer';

function App() {

  return (

    <Router>
      <HeaderContainer></HeaderContainer>
      <Switch>
        <Route exact path="/">
          <Redirect to="/main" />
        </Route>
        <Route path="/login">
          <Container>
            <Login></Login>
          </Container>
        </Route>
        <Route path="/signup">
          <Container>
            <Signup></Signup>
          </Container>
        </Route>
        <Route path="/search">
          <Container>

            <SearchPage />
          </Container>
        </Route>
        <Route path="/searchresults">
          <Container>
            <SearchResultsPage />
          </Container>
        </Route>
        <Route path="/main">
          <MainPage />
        </Route>
      </Switch>
      <Footer></Footer>

    </Router>
  );
}

export default App;
