import { useState } from "react";

export const useLoginForm = () => {
  const [ state, setState ] = useState({
    email: '',
    password: ''
  });

  const handleChange = event => {
    setState(Object.assign({}, state, {
      [event.target.id]: event.target.value      
    }));
  };

  const handleSubmit = event => {

  };
 
  return [state.email, state.password, handleChange, handleSubmit];

};