import React, { Fragment } from 'react';


export const NewestCrewders = () => {
  return (
    <div className="block">
      <h1 className="title has-text-white is-3 has-text-centered   is-family-raleway-black is-spaced">Newest Crewders</h1>
      <div className="columns is-multiline">
        <div className="column is-narrow has-text-centered">
          <figure className="image is-inline-block">
            <img  src="https://res.cloudinary.com/taho-software-services/image/upload/c_fill,h_243,w_305/v1595944345/crewder/profile-pictures/13-1-3253644_dbd9ys.jpg" />

          </figure>

          <div className="mt-1">
            <a className="has-text-white is-family-raleway-semi-bold is-size-5" href="https://www.imdb.com/name/nm3532216/" target="_blank">Heidi</a>
          </div>
        </div>

      </div>
    </div>
  )

};