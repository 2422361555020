import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector"
import en from './resources/en.json';
import sv from './resources/sv.json';
import fi from './resources/fi.json';

import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    fallbackLng: (code) => code,
    debug: true,
    interpolation: { escapeValue: false },
    resources: {
      en,
      sv,
      fi
    }
  });

export default i18n;
