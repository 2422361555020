import React, { Fragment } from 'react';
import { ExploreDepartments } from './explore-departments';
import { NewestCrewders } from './newest-crewders';
import { JoinCrewder } from './join-crewder';
import {useTranslation} from "react-i18next";

import styles from './main-page.module.css';

export const MainPage = () => {

  const { t } = useTranslation();
 
  return (
    <Fragment>
      <section className="section is-size-5 has-background-mainpage">
        <div className="container has-text-white">
          <div className="block">
            <h1 className="title has-text-white is-family-raleway-black mb-6 is-2 has-text-centered">{t('welcome')}</h1>

            <div className="columns is-centered mb-6">
              <div className="column is-narrow">
                <div className="columns is-variable mb-6 is-1 is-rounded">
                  <div className="column is-narrow">
                    <div className="control">
                      <input type="search" className="input has-text-centered has-text-weight-bold is-italic border-radius-left is-medium" placeholder="Profession"></input>

                    </div>
                  </div>
                  <div className="column is-narrow">
                    <div className="control">
                      <input type="text" className="input has-text-weight-bold	 has-text-centered is-italic is-medium" placeholder="Locations"></input>

                    </div>
                  </div>
                  <div className="column is-narrow">
                    <div className="control">
                      <div className="select  is-fullwidth is-medium">
                        <select className="has-select-centered has-text-weight-bold	has-text-maininput is-italic">
                          <option>Experience</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-narrow">
                    <div className="control">

                      <div className="select is-fullwidth  is-medium">
                        <select className="is-italic has-select-centered has-text-weight-bold	has-text-maininput has-text-centered">
                          <option>Department</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-narrow">
                    <button className="button has-searchbtn  is-medium has-text-white">
                      <span className="icon">
                        <i className="fas fa-search"></i>
                      </span>
                    </button>

                  </div>
                </div>
                <div className="columns is-6 is-variable">
                  <div className="column is-narrow" >
                    <div className="field is-horizontal">
                      <label className="label has-text-white is-medium  mr-3">Pay By</label>
                      <div className="field">
                        <input id="taxCard" type="checkbox" className="is-checkradio is-medium has-background-color is-white"></input>
                        <label htmlFor="taxCard">Tax&nbsp;Card</label>
                        <input type="checkbox" id="invoice" className="is-checkradio is-medium has-background-color is-white" />
                        <label htmlFor="invoice">Invoice</label>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field is-horizontal">
                      <label className="label has-text-white is-medium mr-3">Level</label>
                      <div className="field">
                        <input id="professional" type="checkbox" className="is-checkradio pl-0 is-medium has-background-color is-white"></input>
                        <label htmlFor="professional">Professional</label>
                        <input type="checkbox" id="hobbyist" className="is-checkradio pl-0 is-medium has-background-color is-white" />
                        <label htmlFor="hobbyist">Hobbyist</label>
                        <input type="checkbox" id="internship" className="is-checkradio pl-0 is-medium has-background-color is-white" />
                        <label htmlFor="internship">Internship</label>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="columns is-centered">
              <p className="column is-two-thirds has-text-weight-medium">The professional system for audition, evaluation and selection for actors, agencies, casting directors, producers and directors. The professional system for audition, evaluation and selection for actors, agencies, casting directors, producers and directors.</p>
            </div>

            { /*
      <div className={styles.firstrow + ' p-5'}>
        <Row className="justify-content-center">
          <Col xs={12} sm={8} ><h1 className={styles.title} >Rekrytointialusta av-alan ammattilaisille</h1></Col>
        </Row>
        <Row>
          <Col className="position-relative">
            <Image className={styles.firstrowimage1 + " position-absolute"} fluid src={"https://res.cloudinary.com/taho-software-services/image/upload/c_scale,a_10/v1596408236/crewder/intro-pics/search-pic_yzicag.png"} />
            <Image className={styles.firstrowimage2 + " position-absolute"} fluid src={"https://res.cloudinary.com/taho-software-services/image/upload/c_scale,a_340/v1596408236/crewder/intro-pics/search-result-1_iw5hyg.png"} />

          </Col>
        </Row>
      </div>
      <hr />
      <div className={styles.secondrow + ' p-5'}>
        <Row className="justify-content-center">
          <Col xs={12} sm={8} className="position-relative">
            <h2 className={styles.title}>Crewder pähkinänkuoressa</h2>
            <ul className={styles.list}>
              <li>AV-alan digitaalinen palvelualusta
                <ul>
                  <li>Ammattilaisten kohtaamispaikka verkostoitua</li>
                  <li>Sähköinen, maailmanlaajuinen työryhmien rekrytointi</li>
                </ul>
              </li>

              <li>Vähentää tuotantoyhtiöiden hallintokuluja
                <ul>
                  <li>Järjestelmällinen, nopea ja sulava rekrytointiprosessa</li>
                  <li>Parantaa löydettävyyttä, korostaen talenttien merkitystä</li>
                </ul>
              </li>

              <li>Säästää ympäristöä, aikaa ja hermoja
                <ul>
                  <li>Vähemmän tapaamisten ja lentojen tarvetta kohdemaihin</li>
                  <li>Nopeuttaa tuotantojen käynnistämistä, vähentää välikäsiä</li>
                  <li>Tuotanto mahdollista alustaa jo ennen kuin on itse maassa!</li>
                </ul>
              </li>
              <li>"2020-luvun Rolodex"
                <ul>
                  <li>Keskitetty yhteistyökumppanien ja kontaktien katalogi</li>
                </ul>
              </li>
            </ul>
            <Image className={styles.secondrowimage1 + " position-absolute"} fluid src={"https://res.cloudinary.com/taho-software-services/image/upload/c_scale,a_10/v1596408236/crewder/intro-pics/search-pic_yzicag.png"} />

          </Col>


        </Row>
      </div>
      <hr />

      <div className={styles.secondrow + ' p-5'}>
        <Row className="justify-content-end">
        <Col xs={12} sm={6} className="text-right">
        <Image className={styles.firstrowimage1} fluid src={"https://res.cloudinary.com/taho-software-services/image/upload/c_scale,a_-10/v1596408236/crewder/intro-pics/search-pic_yzicag.png"} />

        </Col>
          <Col xs={12} sm={6} className="position-relative">
            <h2 className={styles.title}>AV-tekijöiden hakukone</h2>
            <ul className={styles.list}>
              <li>AV-alan digitaalinen palvelualusta
                <ul>
                  <li>Ammattilaisten kohtaamispaikka verkostoitua</li>
                  <li>Sähköinen, maailmanlaajuinen työryhmien rekrytointi</li>
                </ul>
              </li>

              <li>Vähentää tuotantoyhtiöiden hallintokuluja
                <ul>
                  <li>Järjestelmällinen, nopea ja sulava rekrytointiprosessa</li>
                  <li>Parantaa löydettävyyttä, korostaen talenttien merkitystä</li>
                </ul>
              </li>

              <li>Säästää ympäristöä, aikaa ja hermoja
                <ul>
                  <li>Vähemmän tapaamisten ja lentojen tarvetta kohdemaihin</li>
                  <li>Nopeuttaa tuotantojen käynnistämistä, vähentää välikäsiä</li>
                  <li>Tuotanto mahdollista alustaa jo ennen kuin on itse maassa!</li>
                </ul>
              </li>
              <li>"2020-luvun Rolodex"
                <ul>
                  <li>Keskitetty yhteistyökumppanien ja kontaktien katalogi</li>
                </ul>
              </li>
            </ul>
          </Col>


        </Row>
      </div>
      */ }
          </div>
          <ExploreDepartments />
          <NewestCrewders />
        </div>


      </section>
      
    </Fragment>



  )

};