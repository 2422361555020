import React, { Fragment, useState, useEffect } from "react";
import { CrewderBreadcrumb } from "../shared/crewder-breadcrumb";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { FormSection } from "../shared/form-section";
import { useForm } from "react-hook-form";
import { CrewderButton } from "../shared/crewder-button";
import { getAllNationalities, getAllTitles, getAllExperiences } from "../../services/koodisto.service";
import { CrewderOptions } from "../shared/crewder-options";
import * as _ from 'lodash';
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { PersonalDetails } from "./personal-details";

export const Signup = () => {
  let match = useRouteMatch();


  const [loading, setLoading] = useState(false);
  const [nationalities, setNationalities] = useState([]);
  const [titles, setTitles] = useState([]);
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    Promise.all([getAllNationalities(), getAllTitles(), getAllExperiences()])
      .then(results => {
        setNationalities(results[0]);
        setTitles(results[1]);
        setExperiences(results[2]);

      });

  }, []);
  const onSubmit = values => {
    setLoading(true);

    console.log('values', values);
  };
  return (
    <section className="section">
      <div className="steps">
        <div className="step-item is-completed">
          <div class="step-marker">1
            </div>
          <div class="step-details">
            <p class="step-title">Create Account</p>
          </div>
        </div>
        <div className="step-item is-info is-completed">
          <div className="step-marker">2</div>
          <div className="step-details">
            <p class="step-title">Personal Details</p>
          </div>

        </div>
        <div className="step-item">
          <span className="step-marker">3</span>
          <div className="step-details">
            <p class="step-title">Verify Identity</p>
          </div>
        </div>

      </div>
      <Switch>
        <Route path={match.path}>
          <PersonalDetails />
        </Route>
      </Switch>


      {/*

      <Row>
        <Col>
        </Col>
        <Col lg={9}>
          <h4 className="text-primary mb-5 font-weight-bold">Create your Crewder account</h4>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormSection label="Personal information">
              <Row>
                <Col xs lg={6}>
                  <Form.Group controlId="signupForm.Name">
                    <Form.Control isInvalid={errors.name} name="name" ref={register({ required: 'Nimi on pakollinen tieto' })} autoFocus type="text" placeholder="Name"></Form.Control>
                    <Form.Control.Feedback type="invalid">{_.get(errors, 'name.message')}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="signupForm.Password">
                    <Form.Control type="password" name="password"
                      ref={register({
                        required: 'Salasana on pakollinen tieto',
                        minLength: {
                          value: 6,
                          message: 'Salasanan pituuden tulee olla vähintään 6 merkkiä'
                        }
                      })}
                      isInvalid={errors.password}
                      autoComplete="off"
                      placeholder="Password (6+ characters)"></Form.Control>
                    <Form.Control.Feedback type="invalid">{_.get(errors, 'password.message')}</Form.Control.Feedback>

                  </Form.Group>
                  <Form.Group controlId="signupForm.PasswordConfirm">
                    <Form.Control
                      type="password" 
                      isInvalid={errors.passwordConfirm} name="passwordConfirm" placeholder="Retype password" ref={register({
                        required: 'Salasanan varmistus täytyy antaa',
                        validate: val => val === watch('password') ? true : 'Salasanan varmistus tulee täsmätä annettua salasanaa'

                      })} ></Form.Control>
                    <Form.Control.Feedback type="invalid">{_.get(errors, 'passwordConfirm.message')}</Form.Control.Feedback>

                  </Form.Group>
                  <Form.Group controlId="signupForm.Email">
                    <Form.Control type="email" placeholder="Email"></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="signupForm.Phone">
                    <Form.Control type="tel" placeholder="Phone number"></Form.Control>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center" controlId="signupForm.Nationality">
                    <Col xs="auto">
                      <Form.Label>Nationality:</Form.Label>
                    </Col>
                    <Col xs>
                      <Form.Control className="bg-light text-black" name="nationality" as="select" ref={register({ required: true })}>
                        <CrewderOptions options={nationalities} selectLabel="Select nationality" ></CrewderOptions>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

            </FormSection>
            <FormSection label="Professional information">
              <Row>
                <Col xs lg={8}>
                  <Form.Row className="align-items-center">
                    <Form.Label column xs="auto" htmlFor="inlineTitle">Title:</Form.Label>
                    <Col xs="auto" >
                      <Form.Control id="inlineTitle" placeholder="Select title" className="bg-light text-black" name="title" as="select" ref={register({ required: true })}>
                        <CrewderOptions options={titles} selectLabel="Select title" />
                      </Form.Control>
                    </Col>
                    <Form.Label className="ml-4" column xs="auto">Experience:</Form.Label>
                    <InputGroup as={Col}>
                      <Form.Control className="bg-light text-black" name="experience" as="select" ref={register({ required: true })}>
                        <CrewderOptions options={experiences} selectLabel="Select experience level" ></CrewderOptions>
                      </Form.Control>
                      <InputGroup.Append>
                        <InputGroup.Text>years</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>


                  </Form.Row>

                </Col>
              </Row>

            </FormSection>
            <Form.Group as={Row} className="justify-content-md-center mt-5">
              <Col xs={3}>
                <CrewderButton type="submit" loading={loading}>Create account</CrewderButton>
              </Col>
              <Col xs={3}>
                <CrewderButton variant="secondary">Cancel</CrewderButton>
              </Col>
            </Form.Group>
          </Form>


        </Col>
      </Row>
                    */}

    </section>
  )

};