import React, { Fragment } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { GeoAlt } from 'react-bootstrap-icons';
import PlacesAutocomplete from 'react-places-autocomplete';
import { SelectedItem } from '../../shared/selected-item';
import { experienceLevels, roles } from '../../../model/koodisto.model';
import styles from './search.module.css';
import { useSearchForm } from '../../../custom-hooks';
import { CrewderSpinner } from '../../shared/crewder-spinner';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { SearchFilterBoxContainer } from '../../shared/search-filter-box-container';


export const SearchForm = ({ initialValues, searching, onSubmit }) => {

  const searchOptions = {
    types: ['(regions)']
  };

  const [fields, handleValueChange, handleObjChange] = useSearchForm(initialValues || {
    searchTerm: '',
    role: 'actor',
    selectedItems: [],
    'experiencelevel-1': true
  });

  return (
    <Fragment>
      <Form onSubmit={(event) => {
        event.preventDefault();
        onSubmit(fields);
      }}>


        <Form.Row className="mb-3">
          <Form.Label column xs="auto" className="mr-2">Looking for</Form.Label>
          <Col xs={12} sm={6} >
            <Form.Control as="select" value={fields.role} onChange={event => handleValueChange('role', event.target.value)}>
              <option>Choose...</option>
              {roles.map(({ value, label }) =>
                <option value={value}>{label}</option>
              )}
            </Form.Control>
          </Col>
        </Form.Row>
        <Form.Row className="mb-3">
          <Form.Label column xs="12" sm="6">
            <GeoAlt size={30}> </GeoAlt>
            <span className="ml-2">Search On Map</span>
          </Form.Label>
          <Col xs={12} sm="auto" className="text-right" >
            <Button variant="primary" type="submit">
              {!searching ? 'SEARCH!' : <CrewderSpinner />}
            </Button>
          </Col>
        </Form.Row>
        <SearchFilterBoxContainer title="Location">
          <Form.Row>
            <Col xs={10} sm={8} >
              <PlacesAutocomplete
                value={fields.searchTerm}
                searchOptions={searchOptions}
                onSelect={item => {
                  const newSelectedItems = [...fields.selectedItems, item];
                  const newObject = {
                    ...fields,
                    searchTerm: '',
                    selectedItems: newSelectedItems
                  };
                  handleObjChange(newObject);
                }}
                onChange={(value) => handleValueChange('searchTerm', value)
                }>
                {({ loading, suggestions, getSuggestionItemProps, getInputProps }) =>
                  <div className="autocomplete-root">
                    <Form.Control id="searchTerm" type="search" {...getInputProps()} placeholder="e.g. Helsinki" />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions
                        .filter(suggestion => !fields.selectedItems.includes(suggestion.description))
                        .map(suggestion => (
                          <div className={styles.autocompleterow} key={suggestion.description} {...getSuggestionItemProps(suggestion)}>
                            <span>{suggestion.description}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                }
              </PlacesAutocomplete>
            </Col>
          </Form.Row>

          <Form.Row>
            {fields.selectedItems.map(selectedItem =>
              <Col xs="auto" className="ml-2" key={selectedItem} >
                <SelectedItem key={selectedItem} label={selectedItem} onRemove={item => {
                  const newSelectedItems = fields.selectedItems.filter(itemSelected => itemSelected !== item);
                  handleValueChange('selectedItems', newSelectedItems);
                }}>
                </SelectedItem>
              </Col>
            )}
          </Form.Row>
        </SearchFilterBoxContainer>
        <Form.Row>
          <Col xs={12} sm={6}>
            <SearchFilterBoxContainer title="Experience Level">
              <Form.Row>
                <Col xs={6}>
                  {experienceLevels.map(({ label, value }) => {
                    const name = 'experiencelevel';
                    const id = name + '-' + value;
                    return (
                      <Form.Check
                        type="checkbox"
                        className="ml-2"
                        id={id}
                        checked={fields[id] || false}
                        name={name}
                        onChange={event => handleValueChange(event.target.id, event.target.checked)} key={value} label={label} />
                    )
                  }
                  )}
                </Col>

              </Form.Row>

            </SearchFilterBoxContainer>
          </Col>
          <Col xs={12} sm={6}>
            <SearchFilterBoxContainer title="Availability">
              <DateRangePicker

                onCallback={(start, end, label) => {
                  console.log('start', start);

                }}
                initialSettings={{
                  autoUpdateInput: true,
                  locale: {
                    format: 'DD.MM.YYYY'

                  },
                  autoApply: true
                }}
              >
                <input type="text" placeholder="Click to select availability range criteria" className="form-control" />
              </DateRangePicker>
            </SearchFilterBoxContainer>
          </Col>

        </Form.Row>

      </Form>
    </Fragment>
  )
};