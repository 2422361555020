import * as _ from 'lodash';
import { useState } from 'react';


export const useValidate = () => {
  const [errors, setErrors] = useState({});
  
  function required(event) {
    if (_.isEmpty(event.target.value)) {
      setErrors(_.assign({}, errors, {
        [event.target.id]: {
          required: 'Kenttä on pakollinen'
        }
      }));
    } else {
      errors[event.target.id] = null;
    }
  }

  return {
    errors,
    setErrors,
    required
  };
};