import { Form, Col, Container } from "react-bootstrap";
import React from 'react';
import styles from './search-filter-box-container.module.css';

export const SearchFilterBoxContainer = ({ children, title }) => {

  return (
    <Form.Group className="border pb-3">
      <Form.Row className="mb-2">
        <Col xs={12}  sm="auto">
          <Form.Label className={styles.fieldsetlabel + ' p-2'}>{title}</Form.Label>
        </Col>
      </Form.Row>
      <Container>
      {children}
      </Container>
    </Form.Group>
  )


};

