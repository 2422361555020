import React, { Fragment } from "react";

export const Footer = () => {
  return (
    <footer className="footer py-6">
      <div className="level is-align-items-flex-start">
        <div className="level-item">
          <a className="is-size-5 is-logo" href="/">
            Crewder
          </a>
        </div>
        <div className="level-item" />
        <div className="level-item" />
        <div className="level-item" />

        <div className="level-item has-text-weight-medium">
          <p className="heading">
            <a className="has-text-footer">Help</a>
          </p>
        </div>
        <div className="level-item has-text-weight-medium has-text-footer">
          <p className="heading">Terms
            </p>
        </div>
        <div className="level-item has-text-weight-medium has-text-footer">
          <p className="heading">
            FAQ
            </p>
        </div>
        <div className="level-item has-text-weight-medium">
          <div className="has-text-right">
            <p className="heading"><a href="mailto:info@crewder.com">info@crewder.com</a></p>
            <p className="heading">+358 40 345 6789</p>
          </div>
        </div>
        <div className="level-item has-text-weight-medium has-text-footer">
          <div  className="has-text-right">
            <p className="heading">Ohjaajankatu 412 A</p>
            <p className="heading">00530 HELSINKI</p>
          </div>
        </div>
      </div>

    </footer>
  );
};