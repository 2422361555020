import React, { Fragment } from 'react';

export const PersonalDetails = () => {
  return (
    <div className="columns is-centered">
      <div className="column is-one-third">
      <h1 className="title has-text-info is-4 has-text-centered has-text-weight-semibold is-spaced">Enter Personal Details</h1>
      <form autoComplete="off">
        <div className="field">
          <div className="control">
            <input autoFocus="true" name="name" className="input is-rounded" nype="text" placeholder="First and last name *" />
          </div>
        </div>
        <div className="field">
        <input id="professional" type="checkbox" className="is-checkradio has-background-color is-info"></input>
         <label htmlFor="professional">Professional</label>
        </div>
      </form>
      </div>
      </div>

  )
}