export const SearchResultsData = [
  {
    name: 'Ally Wei',
    match: 30,
    experience: 8,
    imgsrc: 'https://res.cloudinary.com/taho-software-services/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1595944616/crewder/profile-pictures/0_imnz0z.jpg',
    imgtag: '0_imnz0z'
  },

  {
    name: 'Heidi Lindén',
    match: 60,
    experience: 15,
    imgsrc: 'https://res.cloudinary.com/taho-software-services/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1595944345/crewder/profile-pictures/13-1-3253644_dbd9ys.jpg',
    imgtag: '13-1-3253644_dbd9ys'
  }
];