import { roles, getLabel, getSelectedExperienceLevels } from './../../model/koodisto.model';
export const useSearchResultsDescription = (results, query) => {
  let resultRow = '';
  resultRow += getLabel(roles, query.role);
  const experienceLevels = getSelectedExperienceLevels(query);
  if (experienceLevels.length) {
    resultRow += ', ' + getExperienceLevelString(experienceLevels);
  }

  if (query.selectedItems) {
    console.log('selected', query.selectedItems);
    resultRow += ', location: ' + query.selectedItems.join(' ');
  }

  let resultStr = !results.length ? 'No results found' : `Found ${results.length} results.`;
  return [resultStr, resultRow];
}

function getExperienceLevelString(levels) {
  
  const levelsReduced = levels.reduce((a,b) => {
    if (!a.length) {
      a.push({
        min: b.min,
        max: b.max
      });
      return a;
    }
    const lastElement = [...a].pop();
    if (b.min && lastElement.max === b.min) {
      lastElement.max = b.max;
    } else {
      a.push({
        min: b.min,
        max: b.max
      });
    }
    return a;
  }, []);

  return 'experience: ' + levelsReduced.map(({ min, max }) => {

    if (!max) {
      return '> ' + min + ' years';
    }
    if (!min) {
      return '< ' + max + ' years';
    }
    return `${min} - ${max} years`;

  }).join(', ');

}