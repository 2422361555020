

import { SearchResultsData } from './../model/data.model';
import { getSelectedExperienceLevels } from '../model/koodisto.model';
export const doSearch = props => {

  const experienceLevelsSelected = getSelectedExperienceLevels(props);
  return new Promise((resolve, reject) => {
    const filteredResults = SearchResultsData
      .filter(({ experience }) =>
        !experienceLevelsSelected.length || (experienceLevelsSelected.some(({ min, max }) => filterByExperience(experience, min, max)

        )));
    console.log('filtered results', filteredResults);

    setTimeout(() => resolve(filteredResults), 3000);
  });
};

function filterByExperience(experience, min, max) {
  console.log('nämä', experience, min, max);
  return (!min || experience >= min) && (!max || experience < max)
}