

export const KOODISTO_EXPERIENCELEVEL = 'experiencelevel';


export const getLabel = (koodisto, value) => {
  const koodistoItem = koodisto.find(item => item.value === value);
  if (koodistoItem) {
    return koodistoItem.label;
  }
  return '';
}

export const getSelectedExperienceLevels = (props) => {
  const prefix = KOODISTO_EXPERIENCELEVEL + '-';
  const experienceLevelsSelected = experienceLevels
    .filter(({ value }) => props[prefix + value] && props[prefix + value] === true);
  return experienceLevelsSelected;
};

export const roles = [
  {
    value: 'cinematographer',
    label: 'Cinematographer'
  },

  {
    value: 'actor',
    label: 'Actor'
  },

  {
    value: 'stuntman',
    label: 'Stuntman'
  }

];

export const experienceLevels = [
  {
    value: 'lessthanone',
    min: 0,
    max: 1,
    label: '< 1 year'
  },
  {
    min: 1,
    max: 3,
    value: 'onetothree',
    label:  '1 - 3 years'
  },
  {
    min: 3,
    max: 5,
    value: 'threetofive',
    label:  '3 - 5 years'
  },
  {
    min: 6,
    value: 'overfive',
    label:  '> 5 years'
  }
];