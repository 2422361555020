import { firebase } from '../firebase.config';
const db = firebase.firestore();
export const getAllNationalities = () => {
  return db.collection('nationalities').orderBy('label', 'asc').get()
    .then((snapshot) => {
      const nationalities = [];
      snapshot.forEach((item) => {
        nationalities.push({
          value: item.id,
          label: item.data().label
        });
      });
      return nationalities;

    });
  // return axios.get("https://github.com/Dinuks/country-nationality-list/blob/master/countries.json");

};

export const getAllTitles = () => {
  return new Promise((resolve) => {
    resolve([
      {
        value: 'photographer',
        label: 'Valokuvaaja'
      }
    ]);

  });

};


export const getAllExperiences = () => {
  return new Promise((resolve) => {
    const experienceLevels = [];
    for (let year = 1; year <= 10; year++) {
      experienceLevels.push({
        value: year,
        label: year
      });
    }
    resolve(experienceLevels);

  });

};