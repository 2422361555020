import React, { Fragment, useState, useContext } from 'react';
import { CrewderBreadcrumb } from '../shared/crewder-breadcrumb';
import { SearchForm } from './search-form';
import { doSearch } from '../../services/search.service';
import { useHistory, useLocation} from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { userContext } from '../../user.context';





export const SearchPage = () => {
  const history = useHistory();
  const location = useLocation();
  const value= useContext(userContext);


  const [searching, setSearching] = useState(false);
  const onSearch = query => {

    setSearching(true)
    doSearch(query)
      .then((results) => {
        setSearching(false);
        history.replace('/search', query );
        history.push('/searchresults', {
          query, results
        });
      });
  };


  return (
    <Fragment>
      <CrewderBreadcrumb path={[
        {
          label: 'Crewder',
          path: '/main'
        }
      ]} active="Crew Search" />

      <Row>
        <Col xs={6}>
    <span >{value}</span>
          <SearchForm onSubmit={onSearch} initialValues={location.state} searching={searching}></SearchForm>
        </Col>
      </Row>
    </Fragment>
  )
};