import React from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from './header-search-results.module.css';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from 'react-bootstrap-icons';

export const HeaderSearchResults = () => {
  const history = useHistory();
  return (
    <header className={styles.header + ' fixed-top'}>
      <Container fluid="md">
        <Row className="align-items-center">
          <Col xs="12">
            <Button className={styles.backButton + ' shadow-none'} href="#" onClick={() => history.goBack()}>
              <ChevronLeft></ChevronLeft>
              Back to Search</Button>
          </Col>
        </Row>
        
      </Container>
      </header>
  )

};