import React, { useEffect } from 'react';
import { ListGroup, Col, Row } from 'react-bootstrap';
import styles from './search-results-page.module.css';
import { useLocation, useHistory } from 'react-router-dom';
import { useSearchResultsDescription } from './search-results-description.hook';
import { CrewderImage } from '../shared/crewder-image';

export const SearchResultsPage = props => {
  
  const { state: { results, query }  } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (!results || !query) {
      history.replace('/search');
    }

  });

  useEffect(() => {
    document.getElementById('root').classList.add('bg-header-color');
    document.getElementsByTagName('body')[0].classList.add('bg-header-color');

    return () => {
      document.getElementById('root').classList.remove('bg-header-color');
      document.getElementsByTagName('body')[0].classList.remove('bg-header-color');

    };
  });

  const [resultsStr, queryTextStr] = useSearchResultsDescription(results, query);
  return (
    <div className={styles.searchresultspagecontainer}>
      <h1 className="text-center font-weight-bold">Search Results</h1>
      <Row className={styles.resultsdescription}>
        <Col xs="12" className="center-block">{queryTextStr}</Col>
        <Col xs="12" className="center-block">{resultsStr}</Col>

      </Row>
      <ListGroup className="bg-header-color">
        {
          results.map(({ name, imgsrc, imgtag, match, experience }) =>
            <ListGroup.Item action className={styles.listitem +' bg-header-color'}>
              <Row>
                <Col xs={4} sm={2}>
                  <CrewderImage imgtag={imgtag} />
                </Col>
                <Col xs={8} sm={10}>
                  <h3 className="text-warning">{name}</h3>
                  <ul className="text-white">
                    <li>{match}% match</li>
                    <li>{experience} years of professional XP</li>
                  </ul>
                </Col>
              </Row>
            </ListGroup.Item>
          )
        }
      </ListGroup>
    </div>
  )

};