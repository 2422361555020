import React, { useState, Fragment } from 'react';
import styles from './header.module.css';
import { useLocation, useHistory } from 'react-router-dom';
import { BecomeCrewder } from '../../become-crewder';
import { isAuthStatusChanged } from '../../../../services/auth.service';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [user, setUser] = useState(null);
  const [modalActive, setModalActive] = useState(false);

  const { i18n, t } = useTranslation();
  console.log('i18n', i18n);
  isAuthStatusChanged(user => {
    console.log('user', user);
    setUser(user);

  });

  const isLanguageInactive = (lang) => {
    return i18n.languages[0] !== lang;
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);

  };
  return (
    <header className="has-background-header">
      <nav className="navbar ml-5">
        <div className="navbar-brand">
          <a className="navbar-item is-size-3 is-logo" href="/">
            Crewder
          </a>
          <a role="button" onClick={() => setActive(!active)} className="navbar-burger" aria-label="menu" aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>

        </div>
        <div className={active ? 'navbar-menu is-active' : 'navbar-menu'}>
          <div className="navbar-end mr-4">
            <div className="navbar-item is-family-raleway-black is-size-5 mr-6">
              <a onClick={() => changeLanguage('fi')} className={isLanguageInactive('fi') ? "has-text-inactive" : ""}>FI /&nbsp;</a>
              <a onClick={() => changeLanguage('sv')} className={isLanguageInactive('sv') ? "has-text-inactive" : ""}> SWE /&nbsp;</a>
              <a onClick={() => changeLanguage('en')} className={isLanguageInactive('en') ? "has-text-inactive" : ""}> ENG</a>
            </div>
            <div className="navbar-item is-family-raleway-black mr-6">
              <a className="is-size-5 has-text-black">{t("About")}</a>
            </div>
            {!user &&
              <Fragment>
                <div className="navbar-item is-family-raleway-black mr-6">
            <a className="is-size-5 has-text-black">{t("Login")}</a>
                </div>
                <div className="navbar-item is-family-raleway-black mr-6">
                  <a onClick={() => setModalActive(!modalActive)} className="is-size-5 has-text-black">{t("Join now")}</a>
                </div>
              </Fragment>}

            {user &&
              <div className="navbar-item">
                <a className="has-text-black is-size-5 is-family-raleway-black" onClick={() => history.push('/signup')}>Complete Signup</a>
              </div>}
          </div>

        </div>


      </nav>

      { /* 
 <img src="/crewder-logo.png" width="112" height="28" />
          <Col xs="9" className="text-right">
          {!isAuthenticated && <Button className={styles.loginbutton} onClick={() => history.push('/login') } >
                  Kirjaudu sisään</Button>}
          {isAuthenticated && <Dropdown>
              
              <Dropdown.Toggle id="dropdown-profile" className={styles.menuicon}>
                <Person size={30} color="white" ></Person>
              </Dropdown.Toggle>
              <Dropdown.Menu>
               <Dropdown.Item as="span">Kirjauduttu sisään <b>{user.name}</b></Dropdown.Item> 

               <Dropdown.Item as="button" onClick={() => logout()}>Kirjaudu ulos</Dropdown.Item>

              </Dropdown.Menu>
          </Dropdown> }

          </Col>
        {location.pathname === '/search' && <SearchIcon color="white" className={styles.searchicon + ' position-absolute rounded-circle p-3'} />}
          */
      }
      <BecomeCrewder active={modalActive} closeModal={() => setModalActive(false)} />
    </header>
  )
};